<template>
    <div class="tab-area">
        <div class="row page-title">
            <label>{{ pageTitle }}</label>
        </div>
        <div class="page-details row">
            <b-alert :show="IsDataLoaded && !IsApproveOrRejectEnabled" class="alert-info-new position-fixed fixed-top m-0 rounded-0" variant="info" dismissible>
                The Request is already approved or rejected.
            </b-alert>
            <media-amend-overview class="col-lg-8" :VendorValidationRequest="VendorValidationRequest" :Request="Request" :ID="id"></media-amend-overview>
            <vendor-validation-details class="col-lg-4" :Request="Request" :VendorFormType="vendorType" :ID="id"></vendor-validation-details>
        </div>
    </div>
</template>

<script>
import amendMediaVendorMixin from "../../../mixins/mediaamendworkflowvauthorization";
import * as status from "../../../utilities/VendorWorkflowStates";
  export default {
    name: "VendorValidation",
    mixins: [amendMediaVendorMixin],
    data() {
        return {
            vendorType:"Amend Media",
            Request: {},
        };
    },
    props: ["id"],
    computed: {
        canApproveCurrentWorkflowRequest() {
            return this.$store.getters.canApprovePendingVendorValidation;
        },
        IsApproveOrRejectEnabled() {
            return this.Request && (this.Request.Status == status.VendorValidation || this.Request.Status == status.VendorValidationRejected);
        },
        IsDataLoaded() {
            return this.Request && this.Request.VendorBusinessName ? true : false;
        },
    },
  };
  </script>
